<template>
  <!--新建讲师-->
  <div class="wxq_container">
    <el-form
      class="form"
      :model="formData"
      :rules="rules"
      ref="form"
      :inline="true"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item label="讲师姓名" prop="teaName">
            <el-input
              v-model.trim="formData.teaName"
              placeholder="请输入讲师姓名"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="职称和头衔" prop="teaTitle">
            <el-input
              v-model.trim="formData.teaTitle"
              placeholder="请输入职称和头衔"
              maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="wxq_upload" label="讲师头像" prop="avatar">
            <up-loads
              :callbackList="coverUrl"
              :upStyle="'btnList'"
              ref="isShow"
              @up-success="upLoadImgUrls"
            ></up-loads>
            <ul class="wxq_explain">
              <li><p>建议图片大小200kb以内</p></li>
              <li><p>建议图片分辨率400*400像素</p></li>
              <li><p>支持PNG、JPG、JPEG格式</p></li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="讲师简介" prop="teaDesc">
            <el-input
              type="textarea"
              rows="5"
              v-model="formData.teaDesc"
              placeholder="请输入讲师简介"
              maxlength="2000"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="dialog_submit">
          <el-button class="btn btn_blue" @click="getSave('form')">
            保存
          </el-button>
          <el-button class="btn btn_blue" @click="getSave('form', 2)">
            提交审核
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import upLoads from "@/components/backStageComponent/upLoads";
import API from "@apis/backStageEndAPI/lecturerAPI";
export default {
  name: "createLecturer",
  props: ["id"],
  components: {
    upLoads,
  },
  data() {
    return {
      isDisabled: false,
      coverUrl: [],
      formData: {
        teaId: "", //课程id
        teaName: "", //讲师名称
        teaTitle: "", //讲师头衔
        avatar: "", //头像
        teaDesc: "",
        state: 0,
      },
      type: "",
      rules: {
        teaName: [
          { required: true, message: "请输入讲师姓名", trigger: "blur" },
        ],
        teaTitle: [
          { required: true, message: "请输入职称和头衔", trigger: "blur" },
        ],
        teaDesc: [
          { required: true, message: "请输入讲师简介", trigger: "blur" },
        ],
        avatar: [{ required: true, message: "请选择头像", trigger: "change" }],
      },
    };
  },
  mounted() {
    if (this.id) {
      this.isDisabled = true;
      this.getCourseDetail(this.id);
    }
  },
  methods: {
    getCourseDetail(id) {
      //获取课程详情回显
      API.getTeacherDetail(id).then((res) => {
        this.formData.teaName = res.teaName;
        this.formData.teaTitle = res.teaTitle;
        this.formData.avatar = res.avatar;
        this.formData.teaDesc = res.teaDesc;
        this.coverUrl = res.avatar.split(",");
        this.$refs.isShow.isShow = this.coverUrl.length === 0;
      });
    },
    getSave(formName, val) {
      //保存
      this.$refs[formName].validate((valid) => {
        this.formData.state = val ? val : 0;
        if (valid) {
          if (this.id) {
            //有id调编辑接口，无id调新建接口
            this.formData.teaId = this.id;
            API.getPutTeacher(this.formData).then((res) => {
              this.$emit("closeDialog");
            });
          } else {
            API.getTeacher(this.formData).then((res) => {
              this.$emit("closeDialog");
            });
          }
        } else {
          return false;
        }
      });
    },
    handleChange(val) {
      this.formData.subjectId = val[val.length - 1];
    },
    upLoadImgUrls(val) {
      //上传头像
      this.coverUrl = val;
      this.formData.avatar = val.join(",");
      this.$refs.isShow.isShow = this.coverUrl.length === 0;
    },
  },
};
</script>

<style scoped lang="scss">
.wxq_container {
  ::v-deep {
    .el-form-item__label {
      width: 94px !important;
      color: #7d8592;
      font-weight: 400;
      font-size: 14px;
    }
  }
  ::v-deep .el-form-item {
    width: 100%;
    .el-form-item__content {
      display: block;
      padding-left: 94px;
      .el-cascader {
        width: 100%;
      }
      .el-input__inner,
      .el-textarea__inner {
        border-radius: 14px;
      }
    }
  }
  ::v-deep .wxq_upload {
    .el-form-item__content {
      display: flex;
      .wxq_explain {
        margin-left: 25px;
        padding-top: 14px;
        li {
          position: relative;
          line-height: 28px;
          p {
            margin-left: 12px;
          }
          p:before {
            position: absolute;
            top: 10px;
            left: 0;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--colorBlue);
            content: "";
          }
        }
      }
    }
    .upLoad_list_big {
      width: 105px;
      height: 105px;
    }
    .upload span:before {
      left: 43%;
    }
  }
}
</style>
