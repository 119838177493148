<template>
  <div
    class="wxq_header reception clearfix"
    v-show="params.from == null || params.from == 'unifiedVideo'"
  >
    <!-- <slot name="search"></slot> -->
    <div class="wxq_right_corner">
      <el-dropdown trigger="click" @command="handleCommand">
        <button>
          <img
            class="wxq_portrait"
            :src="avatar != '' && avatar != null ? avatar : defaultPotot"
            alt=""
          />
          {{ titleCut }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        </button>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="manage">管理端</el-dropdown-item> -->
          <el-dropdown-item command="student">学员端</el-dropdown-item>
          <el-dropdown-item command="exit"> 退出 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerBar",
  data() {
    return {
      input2: "",
      titleCut: "管理端",
      avatar: "",
      params: {
        from: null,
        path: "",
        token: "",
      },
      //默认头像
      defaultPotot: require("@/assets/img/studentImg/personalCenter/default-head.png"),
    };
  },
  mounted() {
    this.params = this.$store.state.fusion;
    if (
      sessionStorage.getItem("educationToken") ||
      localStorage.getItem("educationToken")
    ) {
      this.isToken = false;
      if (sessionStorage.getItem("profile")) {
        this.avatar = JSON.parse(sessionStorage.getItem("profile")).avatar;
      }
      if (localStorage.getItem("profile")) {
        this.avatar = JSON.parse(localStorage.getItem("profile")).avatar;
      }
    }
  },
  methods: {
    handleCommand(val) {
      if (val == "student") {
        this.titleCut = "学员端";
        this.$router.push("/home");
      } else if (val == "exit") {
        sessionStorage.clear();
        localStorage.removeItem("educationToken");
        localStorage.removeItem("IMSign");
        localStorage.removeItem("profile");
        this.isToken = true;
        this.$router.push("/home");
      }
    },
  },
};
</script>
<style lang="scss">
.wxq_header {
  margin-bottom: 16px;
  width: 100%;

  .wxq_left_search {
    float: left;
    width: 800px;

    .el-input {
      margin-right: 16px;
      width: 360px;

      input {
        border: none;
      }
    }
  }

  .wxq_right_corner {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    .wxq_avatar_role {
      display: inline-block;
      cursor: pointer;
    }

    .el-dropdown {
      .wxq_portrait {
        margin-right: 8px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        vertical-align: middle;
      }

      button {
        padding: 5px 15px;
        border: none;
        border-radius: 12px;
        background: #fff;
        color: #606266;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;
      }

      .el-icon--right {
        margin-left: 26px;
      }
    }
  }
}
</style>
