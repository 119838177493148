<template>
  <div class="hh_container">
    <div class="hh_wrap">
      <img src="@/assets/img/backStageImg/noData-icon.png" alt="" />
      <p>暂无数据</p>
      <div v-if="!searchType" class="hh_btn">
        <el-button class="btn btn_blue" icon="el-icon-plus" @click="nowAdd"
          >现在添加</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "noneData",
  props: ["searchType", "type"],
  data() {
    return {};
  },
  methods: {
    nowAdd() {
      if (this.type) {
        this.$router.push("addConsult");
      } else {
        this.$emit("showLoads", true);
        this.$emit("showNewBuild", true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hh_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
}
.hh_wrap {
  margin: auto;
  padding-top: 15%;
  img {
    width: 85%;
    height: 84%;
    padding-bottom: 30px;
  }
  p {
    text-align: center;
    padding-bottom: 30px;
  }
  .hh_btn {
    text-align: center;
  }
}
</style>
