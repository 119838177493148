<template>
  <!--讲师库-->
  <div class="container">
    <header-bar></header-bar>
    <div class="tabs">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="本人创建" name="first"></el-tab-pane>
        <el-tab-pane label="他人创建" name="second"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="wxq_lecturer_top">
      <el-form class="form" :inline="true">
        <el-row>
          <el-col :span="10" class="wxq_input">
            <el-select
              :disabled="activeName == 'second'"
              clearable
              v-model="formData.state"
              placeholder="请选择"
            >
              <el-option value="" label="全部状态"></el-option>
              <el-option
                v-for="item in lecturerState"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="10" class="wxq_input">
            <el-input
              clearable
              v-model="formData.teaName"
              placeholder="请输入讲师姓名"
              maxlength="10"
              show-word-limit
            />
          </el-col>
          <el-col :span="2">
            <el-button class="btn btn_blue btn_search" @click="search">搜索</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div
        class="wxq_construction btn_orange box_shadow"
        @click="getAddLecturer">
        <i class="el-icon-plus"></i>
        <span>新建讲师</span>
      </div>
    </div>
    <lecturer-main
      v-if="dataList.length > 0"
      :dataList="dataList"
      :totalCount="totalCount"
      :type="viewType"
      @getCompile="getCompile"
      @updateLecturer="updateLecturer"
      @pageChange="pageChange"
      :pageSize="formData.pageSize"
    ></lecturer-main>
    <none-data v-else :searchType="true"></none-data>
    <el-dialog
      class="dialog_wrapper"
      :title="createTitle"
      v-if="isCurriculum"
      :visible.sync="isCurriculum"
      :modal-append-to-body="false"
      top="15vh"
      width="550px"
    >
      <create-lecturer
        :id="currentId"
        @closeDialog="getCloseDialog"
      ></create-lecturer>
    </el-dialog>
  </div>
</template>

<script>
import headerBar from "@/components/backStageComponent/layout/headerBar/headerBar";
import lecturerMain from "./components/lecturerMain";
import createLecturer from "./components/createLecturer";
import noneData from "@comps/backStageComponent/noneData";
import API from "@apis/backStageEndAPI/lecturerAPI";
import CommonUtils from "@utils";
export default {
  name: "index",
  components: {
    headerBar,
    lecturerMain,
    createLecturer,
    noneData,
  },
  data() {
    return {
      activeName: 'first',
      formData: {
        creatorBy: 1,//创建人：1自己2他人
        state: '',//状态：0未发布，1已发布，2审核中，3已驳回，9下架
        teaName: '',//讲师名称
        page: 1,
        pageSize: 12,
      },
      dataList: [],
      totalCount: 0,
      createTitle: '新建讲师',
      isCurriculum: false,//新建讲师开关
      currentId: '',
      viewType: '',
      lecturerState: [
        {id: 0, label: '未发布'},
        {id: 1, label: "已发布"},
        {id: 2, label: "待审核"},
        {id: 3, label: "已驳回"},
        {id: 9, label: "已下架"},
      ],
    }
  },
  mounted() {
    this.getLecturerList(true);
  },
  methods: {
    getLecturerList(flag) {
      //获取讲师列表
      API.getTeacherList(CommonUtils.parseToParams(this.formData)).then(
        (res) => {
          res.dataList.map((item) => {
            switch (item.state) {
              case 0:
                item.state = "未发布";
                break;
              case 1:
                item.state = "已发布";
                break;
              case 2:
                item.state = "审核中";
                break;
              case 3:
                item.state = "已驳回";
                break;
              case 9:
                item.state = "已下架";
            }
          });
          this.dataList = res.dataList;
          this.totalCount = res.rowCount;
          if (flag) {
            this.rowCount = res.rowCount;
          }
        }
      );
    },
    search() {
      //搜索
      this.formData.page = 1;
      this.getLecturerList();
    },
    getCompile(id) {
      //编辑课程
      this.isCurriculum = true;
      this.createTitle = "编辑讲师";
      this.currentId = id;
    },
    getAddLecturer() {
      //新建讲师
      this.currentId = "";
      this.createTitle = "新建讲师";
      this.isCurriculum = true;
    },
    getCloseDialog() {
      //关闭弹框
      this.isCurriculum = false;
      this.getLecturerList();
    },
    updateLecturer() {
      this.getLecturerList()
    },
    pageChange(val) {
      this.formData.page = val;
      this.getLecturerList();
    },
    handleClick(val) {
      this.formData.teaName = ''
      this.formData.page = 1
      if (val.name == 'first') {
        this.formData.creatorBy = 1
        this.formData.state = ''
        this.viewType = ''
      } else if (val.name == 'second') {
        this.formData.creatorBy = 2
        this.formData.state = 1
        this.viewType = 'viewDetail'
      }
      this.getLecturerList()
    },
  }
}
</script>

<style scoped lang="scss">
.tabs {
  width: 312px;
}
.wxq_lecturer_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding: 24px;
  width: 100%;
  border-radius: 24px;
  background: #fff;
}
.wxq_input {
  margin-right: 20px;
}
.wxq_construction {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 48px;
  border-radius: 14px;
  cursor: pointer;
  span {
    color: #fff;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
  }
  .el-icon-plus:before {
    margin-right: 13px;
    color: #fff;
    font-weight: 800;
    font-size: 20px;
  }
}
</style>
